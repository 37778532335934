import { useState } from "react"
import { ID } from "interfaces"

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Skeleton,
} from "@mui/material"
import { gql, useQuery } from "@apollo/client"
import { GQLStateConfig } from "generated/documentTypes"

const GET_STATE_CONFIGS = gql`
  query GetStateConfigs($pageQuery: PageQuery) {
    stateConfigs(pageQuery: $pageQuery) {
      edges {
        node {
          state
          longName
        }
      }
    }
  }
`

type Edge = {
  node: GQLStateConfig
}

export interface StateSelectProps {
  onChange: (newValue: ID | undefined) => void
  selectedState?: ID
}

const StateSelect: React.FC<StateSelectProps> = ({
  onChange,
  selectedState,
}) => {
  const [selected, setSelected] = useState<ID>(
    selectedState?.toUpperCase() || "",
  )
  const { loading, data } = useQuery(GET_STATE_CONFIGS, {
    variables: { pageQuery: { first: 50, skip: 0 } },
  })

  const sortedEdges = data?.stateConfigs.edges.sort((a: Edge, b: Edge) => {
    if (a.node.state < b.node.state) return -1
    if (a.node.state > b.node.state) return 1
    return 0
  })

  const handleChange = (event: any): void => {
    const newValue = event.target.value as ID
    setSelected(newValue)
    onChange(newValue)
  }

  if (loading) {
    return <Skeleton variant="rectangular" width="100%" height={56} />
  }

  return (
    <FormControl fullWidth>
      <InputLabel>State</InputLabel>
      <Select
        placeholder="Select a state"
        label="State"
        fullWidth
        value={selected}
        onChange={handleChange}
        data-cy="us-state-select"
      >
        {sortedEdges?.map((edge: Edge) => (
          <MenuItem
            key={edge.node.state}
            value={edge.node.state}
            data-cy="state-menu-item"
          >
            {edge.node.longName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default StateSelect
