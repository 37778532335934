import { Menu as MenuIcon } from "@mui/icons-material"
import {
  AppBar,
  Box,
  Drawer,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import i18next from "../i18n.js"

import SignInButton from "./SignInButton"

const USER_NAVIGATION = [
  {
    label: i18next.t("Home"),
    href: "/",
    showInHeader: false,
  },
  {
    label: i18next.t("Drivers Ed"),
    href: "/courses",
    showInHeader: true,
  },
  {
    label: i18next.t("Driving Lessons"),
    href: "/book",
    showInHeader: true,
  },
  {
    label: i18next.t("Book"),
    href: "/location-search",
    showInHeader: true,
  },
  {
    label: i18next.t("Pricing"),
    href: "/pricing",
    showInHeader: true,
  },
  {
    label: i18next.t("Reviews"),
    href: "/coastline-academy-driving-school-reviews",
    showInHeader: true,
  },
  {
    label: i18next.t("About"),
    href: "/article/about-coastline-academy",
    showInHeader: true,
  },
  {
    label: i18next.t("DMV Practice Test"),
    href: "/dmv-practice-test",
    showInHeader: false,
  },
  {
    label: i18next.t("How to Get a License"),
    href: "/how-to-get-drivers-license",
    showInHeader: false,
  },
  {
    label: i18next.t("Articles"),
    href: "/articles",
    showInHeader: false,
  },
  {
    label: i18next.t("Contact"),
    href: "/contact",
    showInHeader: false,
  },
  {
    label: i18next.t("Affiliates"),
    href: "/affiliate-signup",
    showInHeader: false,
  },
  {
    label: i18next.t("Sell Your School"),
    href: "/article/selling-your-driving-school-to-coastline",
    showInHeader: false,
  },
]

interface HeaderProps {
  hideNav?: boolean
}

const Header = (props: HeaderProps): JSX.Element => {
  const router = useRouter()
  const theme = useTheme()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [isSmall, setIsSmall] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const handleScroll = (): void => setIsSmall(window.scrollY > 100)
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleToggle = (): void => {
    setDrawerOpen(!drawerOpen)
  }

  const handleSignIn = (): void => {
    setDrawerOpen(false)
    router.push("/profile")
  }

  const handleLanguageChange = (): void => {
    i18n.changeLanguage(i18n.language === "en" ? "es" : "en")
  }

  return (
    <header data-cy="main-header">
      <AppBar position="fixed" color="inherit" elevation={1}>
        <Toolbar style={{ background: theme.palette.primary.main }}>
          <Box sx={{ flex: 1, display: "flex", position: "relative" }}>
            <Fade
              in={isSmall}
              timeout={500}
              style={{
                position: "absolute",
                left: 0,
                top: isMobile ? "-12px" : "-17px",
              }}
            >
              <Link href="/" style={{ display: "flex", alignItems: "center" }}>
                <Image
                  src="/images/coastline-logo-mark.png"
                  alt="Coastline Academy Logo"
                  width={isMobile ? "34" : "50"}
                  height={isMobile ? "24" : "35"}
                />
              </Link>
            </Fade>
            <Fade
              in={!isSmall}
              timeout={500}
              style={{
                position: "absolute",
                left: 0,
                top: isMobile ? "-12px" : "-17px",
              }}
            >
              <Link href="/" style={{ display: "flex", alignItems: "center" }}>
                <Image
                  src="/images/coastline-logo.png"
                  alt="Coastline Academy Logo"
                  width={isMobile ? "205" : "290"}
                  height={isMobile ? "24" : "34"}
                />
              </Link>
            </Fade>
          </Box>
          {router.pathname.indexOf("/future/book-lesson/") === -1 &&
          !props.hideNav ? (
            <>
              <nav>
                {!isMobile &&
                  USER_NAVIGATION.filter((item) => item.showInHeader).map(
                    (item) => (
                      <Link
                        href={item.href}
                        key={item.href}
                        style={{
                          color: theme.palette.secondary.main,
                          fontWeight: 600,
                          fontSize: "0.9em",
                          textDecoration: "none",
                          paddingRight: "24px",
                        }}
                      >
                        {item.label}
                      </Link>
                    ),
                  )}
              </nav>
              <SignInButton />
              <IconButton
                aria-label="Menu"
                sx={{ ml: 1 }}
                onClick={handleToggle}
                data-cy="header-nav-button"
                color="secondary"
              >
                <MenuIcon />
              </IconButton>
            </>
          ) : null}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        data-cy="menu-drawer"
      >
        <List>
          {USER_NAVIGATION.map((item) => (
            <ListItem
              key={item.href}
              disablePadding
              onClick={() => {
                setDrawerOpen(false)
                router.push(item.href)
              }}
            >
              <ListItemButton>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding onClick={() => setDrawerOpen(false)}>
            <ListItemButton>
              <ListItemText
                primary={
                  <a
                    href="https://coastline.breezy.hr"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: theme.palette.text.primary }}
                  >
                    {t("Careers")}
                  </a>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={() => handleSignIn()}>
            <ListItemButton>
              <ListItemText primary={t("Sign In")} />
            </ListItemButton>
          </ListItem>
          {process.env.NODE_ENV === "development" && (
            <ListItem disablePadding onClick={() => handleLanguageChange()}>
              <ListItemButton>
                <ListItemText
                  primary={i18n.language === "en" ? "Español" : "English"}
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Drawer>
    </header>
  )
}

export default Header
