import { gql } from "@apollo/client"
import { Button } from "@mui/material"
import { useSignOutMutation } from "generated/documentTypes"
import Link from "next/link"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"

import { Logger } from "Logger"
import { useEffect } from "react"
import { useUser } from "./useUser"
const Log = Logger("components/Header")

gql`
  mutation SignOut {
    signOut
  }
`

const PREFIX = "Header"
const classes = {
  root: `${PREFIX}-root`,
  signIn: `${PREFIX}-signIn`,
  pageTitle: `${PREFIX}-pageTitle`,
}

const SignInButton: React.FC = () => {
  const router = useRouter()
  const { user, isLoading } = useUser({})
  const { t } = useTranslation()

  const [signOut, signOutState] = useSignOutMutation()

  let button: React.ReactNode = (
    <Link href="/profile" passHref>
      <Button
        size="small"
        sx={{ borderColor: "accentTwo.main", color: "accentTwo.main" }}
        variant="outlined"
        className={classes.signIn}
      >
        {t("Sign In")}
      </Button>
    </Link>
  )

  async function signOutAndNavigate(): Promise<void> {
    const L = Log.fork("Header/signOutAndNavigate")

    try {
      await signOut()
    } catch (e) {
      L.warn("Error signing out", e)
    }

    try {
      await signOutState.client.clearStore()
      await signOutState.client.resetStore()
      L.info("Cache cleared")
    } catch (e) {
      L.warn("Error clearing out cache", e)
    }
  }

  useEffect(() => {
    if (signOutState.data?.signOut) {
      router.push("/logout")
    }
  }, [signOutState, user])

  if (isLoading) {
    button = null
  }

  if (user !== undefined) {
    button = (
      <Button
        size="small"
        className={classes.signIn}
        variant="outlined"
        sx={{ borderColor: "accentTwo.main", color: "accentTwo.main" }}
        disabled={signOutState.loading}
        onClick={signOutAndNavigate}
      >
        {t("Sign Out")}
      </Button>
    )
  }

  return button
}

export default SignInButton
